import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getEmployeesApi,
  createEmployeeApi,
  editEmployeeApi,
  deleteEmployeeApi,
} from './api';
import { uploadFile } from '../common/thunks';


export const getEmployees = createAsyncThunk(
  'employees/get',
  async (payload) => {
    const { data } = await getEmployeesApi(payload);

    return data;
  },
);

export const createEmployee = createAsyncThunk(
  'employee/create',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      let avatarId;
      if (payload?.data?.avatar?.file) {
        const { payload: { fileId } } = await dispatch(uploadFile({ file: payload?.data?.avatar?.file }));
        avatarId = fileId;
      }

      const { data } = await createEmployeeApi({
        companyId: payload.companyId,
        data: { ...payload.data, avatarId },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editEmployee = createAsyncThunk(
  'employee/edit',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      let avatarId;
      if (payload?.data?.avatar?.file) {
        const { payload: { fileId } } = await dispatch(uploadFile({ file: payload?.data?.avatar?.file }));
        avatarId = fileId;
      }

      const { data } = await editEmployeeApi({
        companyId: payload.companyId,
        data: { ...payload.data, avatar: undefined, avatarId },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteEmployee = createAsyncThunk(
  'employee/delete',
  async (payload) => {
    await deleteEmployeeApi(payload);

    return payload.id;
  },
);
