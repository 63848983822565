import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getCompaniesApi,
  createCompanyApi,
  editCompanyApi,
  deleteCompanyApi,
  getCompanyApi,
  createResaleUnitApi,
  updateUnitApi,
  getResaleCompaniesApi,
  getResaleCompanyUnitsApi,
  getCompanyResaleUnitsApi,
  publishResaleUnitApi,
  declineResaleUnitApi,
  deleteUnitApi,
} from './api';

import { detachFilesApi, uploadFileApi } from '../common/api';


export const getCompanies = createAsyncThunk(
  'companies/get',
  async (payload) => {
    const { data } = await getCompaniesApi(payload);

    return data;
  },
);

export const getCompany = createAsyncThunk(
  'company/get',
  async (id) => {
    const { data } = await getCompanyApi(id);

    return data;
  },
);

export const createCompany = createAsyncThunk(
  'company/create',
  async (company, { rejectWithValue }) => {
    try {
      let id;
      let url;

      if (company?.logo?.file) {
        const form = new FormData();
        form.append('file', company?.logo?.file);
        const { data } = await uploadFileApi(form);
        id = data.fileId;
        url = data.url;
      }

      const { data } = await createCompanyApi({ ...company, logoId: id, logo: url });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editCompany = createAsyncThunk(
  'company/edit',
  async (company, { rejectWithValue }) => {
    try {
      let id;
      let url;

      if (company?.logo?.file) {
        const form = new FormData();
        form.append('file', company?.logo?.file);
        const { data } = await uploadFileApi(form);
        id = data.fileId;
        url = data.url;
      }

      const { data } = await editCompanyApi({ ...company, logoId: id, logo: url });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteCompany = createAsyncThunk(
  'company/delete',
  async (company, { rejectWithValue }) => {
    try {
      await deleteCompanyApi(company);

      return company;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getResaleCompanies = createAsyncThunk(
  'resaleCompanies/get',
  async () => {
    const { data } = await getResaleCompaniesApi();

    return data;
  },
);

export const getResaleCompanyUnitsForAdmins = createAsyncThunk(
  'resaleCompanyUnits/get',
  async (companyId) => {
    const { data } = await getResaleCompanyUnitsApi(companyId);

    return data;
  },
);

export const getCompanyResaleUnitsForBrokers = createAsyncThunk(
  'companyUnits/get',
  async (company) => {
    const { data } = await getCompanyResaleUnitsApi(company);

    return data;
  },
);

export const createCompanyResaleUnit = createAsyncThunk(
  'companyResaleUnit/create',
  async (unit, { rejectWithValue }) => {
    try {
      const layouts = unit?.files?.layout && await Promise.all(unit.files.layout.map(async (item) => {
        const form = new FormData();
        form.append('file', item.file);
        const res = await uploadFileApi(form);

        return {
          ...res.data,
          fileId: res.data.fileId,
          scope: 'layout',
          description: undefined,
        };
      }));

      const documents = unit?.files?.documents && await Promise.all(unit.files.documents.map(async (item) => {
        const form = new FormData();
        form.append('file', item.file);
        form.append('additionalInfo', JSON.stringify(item.additionalInfo));
        const res = await uploadFileApi(form);

        return {
          ...res.data,
          fileId: res.data.fileId,
          scope: 'documents',
          description: undefined,
        };
      }));

      const { data } = await createResaleUnitApi({
        ...unit,
        files: {
          layout: [...(unit?.fiels?.layout || []), ...(layouts || [])].filter((item) => item),
          documents: [...(unit?.fiels?.documents || []), ...(documents || [])].filter((item) => item),
        },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateUnit = createAsyncThunk(
  'unit/update',
  async (unit, { rejectWithValue }) => {
    try {
      const layouts = unit?.files?.layout && await Promise.all(unit.files.layout.map(async (item) => {
        if (item.changeStatus === 'detach') {
          await detachFilesApi({ ids: [item.fileId] });
          return null;
        }

        if (!item.file) return { ...item, translations: item.translations || undefined };

        const form = new FormData();
        form.append('file', item.file);
        const { data } = await uploadFileApi(form);

        return {
          ...data,
          fileId: data.fileId,
          order: item.order,
          translations: item.translations,
          scope: 'layout',
        };
      })).then((res) => res.filter(Boolean));

      const documents = unit?.files?.documents && await Promise.all(unit.files.documents.map(async (item) => {
        if (!item.file) return { ...item, description: item.description || undefined };

        const form = new FormData();
        form.append('file', item.file);
        form.append('additionalInfo', JSON.stringify(item.additionalInfo));
        const res = await uploadFileApi(form);

        return {
          ...res.data,
          fileId: res.data.fileId,
          scope: 'documents',
          description: undefined,
        };
      }));

      const { data } = await updateUnitApi({
        ...unit,
        files: {
          ...unit.files,
          layout: layouts,
          documents,
        },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const publishResaleUnit = createAsyncThunk(
  'companyResaleUnit/publish',
  async ({ unit }, { rejectWithValue }) => {
    try {
      const { data } = await publishResaleUnitApi(unit);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const declineResaleUnit = createAsyncThunk(
  'companyResaleUnit/decline',
  async ({ unit, reason }, { rejectWithValue }) => {
    try {
      const { data } = await declineResaleUnitApi({ ...unit, reason });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteUnit = createAsyncThunk(
  'unit/delete',
  async (unit, { rejectWithValue }) => {
    try {
      await deleteUnitApi(unit);

      return unit;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
