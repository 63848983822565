import { createAsyncThunk } from '@reduxjs/toolkit';

import { getExchangeApi } from './api';


export const getExchange = createAsyncThunk(
  'units/getExchange',
  async () => {
    const { data } = await getExchangeApi();

    const AED = data.find(({ to }) => to === 'AED').rate;
    const RUB = data.find(({ to }) => to === 'RUB').rate;
    const USD = data.find(({ to }) => to === 'USD').rate;
    const EUR = data.find(({ to }) => to === 'EUR').rate;
    const GBP = data.find(({ to }) => to === 'GBP').rate;
    const INR = data.find(({ to }) => to === 'INR').rate;
    const TRY = data.find(({ to }) => to === 'TRY').rate;
    const CNY = data.find(({ to }) => to === 'CNY').rate;
    const CAD = data.find(({ to }) => to === 'CAD').rate;
    const AUD = data.find(({ to }) => to === 'AUD').rate;

    return {
      AED,
      RUB,
      USD,
      EUR,
      GBP,
      INR,
      TRY,
      CNY,
      CAD,
      AUD,
    };
  },
);
