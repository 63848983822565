import { createNextState } from '@reduxjs/toolkit';

import request from '../common/utils';

import { transfotmDescription } from './transformers';


export const getCompaniesApi = (params) => request({
  url: '/companies',
  method: 'GET',
  params,
});

export const createCompanyApi = (data) => request({
  url: '/companies',
  method: 'POST',
  data,
});

export const editCompanyApi = (data) => request({
  url: `/companies/${data.id}`,
  method: 'PUT',
  data,
});

export const getCompanyApi = (id) => request({
  url: `/companies/${id}`,
  method: 'GET',
});

export const deleteCompanyApi = (id) => request({
  url: `/companies/${id}`,
  method: 'DELETE',
});

export const getResaleCompaniesApi = () => request({
  url: '/resale/companies',
  method: 'GET',
});

export const getResaleCompanyUnitsApi = (id) => request({
  url: `/resale/companies/${id}`,
  method: 'GET',
});

export const getCompanyResaleUnitsApi = async () => {
  let { data } = await request({
    url: '/company/units',
    method: 'GET',
  });

  data = data.map(createNextState((item) => {
    item.files.documents = (item.files.documents || []).map((doc) => transfotmDescription(doc));
    item.files.layout = (item.files.layout || []).map((lay) => transfotmDescription(lay));
  }));

  return { data };
};

export const createResaleUnitApi = (unit) => request({
  url: `/projects/${unit.projectId}/units`,
  method: 'post',
  data: unit,
});

export const updateUnitApi = (unit) => request({
  url: `/projects/${unit.projectId}/units/${unit.id}`,
  method: 'PUT',
  data: unit,
});

export const publishResaleUnitApi = (unit) => request({
  url: `/projects/${unit.projectId}/units/${unit.id}/publish`,
  method: 'POST',
});

export const declineResaleUnitApi = (unit) => request({
  url: `/projects/${unit.projectId}/units/${unit.id}/decline`,
  method: 'POST',
  data: { reason: unit.reason },
});

export const deleteUnitApi = (unit) => request({
  url: `/projects/${unit.projectId}/units/${unit.id}`,
  method: 'DELETE',
});
