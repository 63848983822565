import request from '../common/utils';


export const getDistrictsApi = (params) => request({
  url: '/districts',
  method: 'GET',
  params,
});

export const createDistrictApi = (data) => request({
  url: '/districts',
  method: 'POST',
  data,
});

export const editDistrictApi = (data) => request({
  url: `/districts/${data.id}`,
  method: 'PUT',
  data,
});

export const deleteDistrictApi = (data) => request({
  url: `/districts/${data.id}`,
  method: 'DELETE',
});

export const getDistrictApi = (id) => request({
  url: `/districts/${id}`,
  method: 'GET',
});

export const getDistrictDevelopersApi = ({ districtId }) => request({
  url: `/districts/${districtId}/developers`,
  method: 'GET',
});


export const getDistrictImagesApi = ({ districtId }) => request({
  url: `/districts/${districtId}/images`,
  method: 'GET',
});

export const attachDistrictToProjectApi = ({ districtId, ids }) => request({
  url: `/districts/${districtId}/images`,
  method: 'POST',
  data: { ids },
});
