import { createSlice, createAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { getExchange } from './thunks';
import { setLanguage } from '../common/utils';


const setUnit = createAction('setUnit');

const initialState = {
  lang: window.localStorage.getItem('lang') || 'en',
  priceUnit: window.localStorage.getItem('priceUnit') || 'USD',
  squareUnit: window.localStorage.getItem('squareUnit') || 'ft²',
  exchange: {
    RUB: null,
    USD: null,
    AED: 1,
  },
};

setLanguage(initialState.lang);

const reducers = {
  [setUnit]: (state, { payload: { type, val } }) => {
    state[type] = val;
    window.localStorage.setItem(type, val);
    if (type === 'lang') {
      i18next.changeLanguage(val);
      setLanguage(val);
    }
  },
};

const extraReducers = (builder) => builder
  .addCase(getExchange.fulfilled, (state, { payload }) => {
    state.exchange = payload;
  });


export const { actions, reducer, name } = createSlice({
  name: 'units',
  initialState,
  reducers,
  extraReducers,
});
