import React from 'react';
import { t } from 'i18next';

import Notification from 'components/Notification';
import { copyToBuffer } from 'components/utils';
import palette from 'palette';
import { actions as catalogActions } from 'store/catalog/reducers';
import { actions as authActions } from 'store/auth/reducers';

import { c as CheckInCircle } from 'assets/svg/CheckInCircle.svg';
import { c as Alert } from 'assets/svg/Alert.svg';


const handleLogout = (dispatch) => {
  Notification({
    image: <Alert fill={`rgba(${palette.uiColorsSystemYellow} / 1)`} height={32} width={32} />,
    title: t('ws.auth.logout'),
  });

  dispatch(authActions.logout());
  dispatch(catalogActions.clearCatalog());
};

const closeCode = 4999;

class WS {
  instance = null;

  count = 0;

  store = null;

  token = null;

  constructor(store) {
    this.store = store;
    this.store.subscribe(this.handleChange.bind(null, store));
  }

  init = () => {
    const { token } = this.store.getState().auth;
    this?.instance?.close(closeCode);
    if (!token) return;
    // this.instance = new WebSocket(`wss://stage.geniemap.net/ws?bearer=${token}`);
    this.instance = new WebSocket(`wss://${window.location.host}/ws?bearer=${token}`);

    this.instance.addEventListener('open', this.onOpen);
    this.instance.addEventListener('close', this.onClose);
    this.instance.addEventListener('message', this.onMessage);
    // this.instance.addEventListener('error', this.onClose);

    return this;
  };

  onOpen = () => {
    this.count = 0;
    console.log('WS Connected...'); // eslint-disable-line
  };

  onClose = (e) => {
    const { code } = e;
    if (code === closeCode) return;
    this.count += 1;
    const delay = Math.min(2 ** this.count, 10);
    const { dispatch } = this.store;

    if (this.count > 10) {
      handleLogout(dispatch);
    }

    setTimeout(() => {
      this.init(this.store); // eslint-disable-line
    }, delay * 1000);
  };

  onMessage = (e) => {
    const res = JSON.parse(e.data);
    const { dispatch } = this.store;

    if (res.event === 'catalog_generated') {
      Notification({
        image: <CheckInCircle fill={`rgba(${palette.uiColorsSystemGreen} / 1)`} height={32} width={32} />,
        title: t(`ws.catalog.${res.event}`),
        interactive: (
          <>
            <a href={res.data.link} target="_blank" rel="noreferrer">{t('Download PDF')}</a>
            <span> | </span>
            <a onClick={() => copyToBuffer(res.data.link)}>{t('Copy link')}</a> {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
          </>
        ),
      }, { autoClose: false });
    }

    if (res.event === 'logout') {
      handleLogout(dispatch);
    }
  };

  handleChange = () => {
    const prev = this.token;
    this.token = this.store.getState().auth.token;

    if (prev !== this.token) this.init();
  };
}

export default WS;
