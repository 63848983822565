import { createSlice } from '@reduxjs/toolkit';


const initialFilters = {
  emirateIds: [],
  districtIds: [],
  developerIds: [],
  q: '',
  costLevels: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  minSquare: undefined,
  maxSquare: undefined,
  minServiceCharge: undefined,
  maxServiceCharge: undefined,
  isBuilt: 0,
  layouts: [],
  finishingIds: [],
  unitTypeIds: [],
  statuses: [],
  viewpoint: undefined,
  favorite: undefined,
  isLaunch: undefined,
};

const initialState = {
  openedFilter: undefined,
  filtersData: initialFilters,
};

const reducers = {
  changeFilters: (state, { payload }) => {
    state.filtersData = {
      ...state.filtersData,
      ...payload,
    };
  },
  changeOpenedFilter: (state, { payload }) => {
    state.openedFilter = payload;
  },
  clearFilters: (state) => {
    state.filtersData = initialFilters;
  },
};

export const { actions, reducer, name } = createSlice({
  name: 'filters',
  initialState,
  reducers,
});
