import { createAsyncThunk } from '@reduxjs/toolkit';

import { setToken } from 'store/common/utils';

import {
  authApi,
  userApi,
  forgotPasswordApi,
  resetPasswordApi,
} from './api';


export const auth = createAsyncThunk(
  'auth/auth',
  async ({ email, password }) => {
    const { data: { token } } = await authApi({ email, password });

    window.localStorage.setItem('token', token);
    setToken(`Bearer ${token}`);

    return token;
  },
);

export const getUser = createAsyncThunk(
  'auth/user',
  async () => {
    const { data } = await userApi();

    return data;
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await forgotPasswordApi({ email });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await resetPasswordApi(payload);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);
