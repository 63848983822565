const statuses = {
  available: 'Available',
  onModeration: 'On moderation',
  declined: 'Declined',
  soldOut: 'Sold out',
  unpublished: 'Unpublished',
};

const floorsList = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  null: '',
};

const heightsList = {
  Simplex: 'Simplex',
  Duplex: 'Duplex',
  Triplex: 'Triplex',
  Quadruplex: 'Quadruplex',
  FiveFloors: 'Five floors',
  SixFloors: 'Six floors',
  simplex: 'Simplex',
  duplex: 'Duplex',
  triplex: 'Triplex',
  quadruplex: 'Quadruplex',
  fivefloors: 'Five floors',
  sixfloors: 'Six floors',
};

const units = {
  units_one: '{{count}} unit',
  units_few: '{{count}} units',
  units_many: '{{count}} units',
  units_other: '{{count}} units',
};

module.exports = {
  translation: {
    heightsList,
    main: {
      controls: {
        top: {
          emirate: 'Emirate',
          district: 'District',
          developer: 'Developer',
          moreFilters: 'More filters',
          clearFilters: 'Clear filters',
        },
      },
      projectEdit: {
        create: 'Create project',
        edit: 'Edit project',
        description: 'Description',
        save: 'Save',
        tabs: {
          information: 'Information',
          units: 'Units',
          amenities: 'Amenities',
          paymentPlans: 'Payment plans',
          documents: 'Documents',
        },
        information: {
          name: 'Name',
          namePlaceholder: 'Name',
          add: 'Add',
          description: 'Description',
          developer: 'Developer',
          seller: 'Seller',
          postHandover: 'Post handover',
          handover: 'Handover',
          serviceCharge: 'Service charge',
          furnishing: 'Furnishing',
          startOfSales: 'Start of sales',
          eoi: 'EOI',
          noc: 'Resale allowed after',
          isLaunch: 'Launch',
          isPublished: 'Published',
          isExclusive: 'Exclusive',
          save: 'Save',
          architecture: 'Architecture',
          interior: 'Interior',
          amenities: 'Amenities',
          projectLocation: 'Project location (coordinates)',
          projectLocationPlaceholder: 'Paste coordinates',
          common: 'Common',
        },
        paymentPlans: {
          plan: 'Plan',
          add: 'Add new plan',
          aboutPlan: 'About plan',
          postHandover: 'Post handover',
          deletePlan: 'Delete plan',
          name: 'Plan name',
          description: 'Plan description',
          stepName: 'Name of payment step',
          stepDescription: 'Description of payment step',
          save: 'Save',
          cash: 'Cash',
          paypal: 'Bank transfer',
          transfer: 'Master check',
          crypto: 'Crypto',
        },
        amenities: {
          save: 'Save',
        },
        documents: {
          add: 'Add',
          save: 'Save',
        },
        units: {
          search: 'Unit search',
          download: 'Download units',
          uploadPlans: 'Upload plans',
          plan: 'Plan',
          number: 'Number',
          type: 'Type',
          layout: 'Layout',
          height: 'Height',
          floor: 'Floor',
          size: 'Size ft²',
          square: 'Size ft²',
          price: 'Price AED',
          view: 'View',
          views: 'View',
          action: 'Action',
          from: 'From',
          to: 'To',
          soldOut: 'Sold out',
          soldOutShow: 'Show sold out',
          soldOutHide: 'Hide sold out',
          save: 'Save',
          heightsList,
          ...units,
          unitEdit: {
            selectLayout: 'Select layout',
            layoutName: 'Layout name',
            search: 'Search',
            unitsList: 'Units list',
            type: 'Type',
            layout: 'Layout',
            height: 'Height',
            floor: 'Floor',
            number: 'Number',
            square: 'Square',
            price: 'Price',
            views: 'Views',
            save: 'Save',
            apply: 'Apply',
            renderersShow: 'All units',
            renderersHide: 'Without layouts',
          },
        },
      },
      leftPanel: {
        districtEdit: {
          createDistrict: 'Create district',
          editDistrict: 'Edit district',
          averagePrice: 'Average price',
          name: 'Name',
          description: 'Description',
          create: 'Create',
          save: 'Save',
        },
        districtView: {
          description: 'Description',
          descriptionHide: 'Hide',
          descriptionShow: 'Show',
          developers: 'Developers',
          projectsCount_one: '{{count}} project',
          projectsCount_few: '{{count}} projects',
          projectsCount_many: '{{count}} projects',
          projectsCount_other: '{{count}} projects',
          from: 'From',
        },
        projectView: {
          description: 'Description',
          descriptionHide: 'Hide',
          descriptionShow: 'Show',
          floor: 'floor',
          resale: 'Resale',
          fullUnitsList: 'Full units list',
          addToCatalog: 'Project to catalog',
          tabs: {
            information: 'Information',
            amenities: 'Amenities',
            paymentPlans: 'Payment plans',
            units: 'Units',
            documents: 'Documents',
            developer: 'Developer',
          },
          paymentPlans: {
            cash: 'Cash',
            paypal: 'Bank transfer',
            transfer: 'Master check',
            crypto: 'Crypto',
          },
          information: {
            developer: 'Developer',
            district: 'District',
            price: 'Price',
            size: 'Size',
            postHandover: 'Post handover',
            handover: {
              title: 'Handover',
              ready: 'Ready',
            },
            unitType: 'Unit type',
            serviceCharge: 'Service charge',
            furnishing: 'Furnishing',
            startOfSales: 'Start of sales',
            eoi: 'EOI',
            noc: 'Resale allowed after',
            managersContacts: 'Developer contacts',
            managersName: 'Name',
            managersPhone: 'Phone',
            hide: 'Hide',
            show: 'Show',
            yes: 'Yes',
            no: 'No',
          },
          units: {
            ...units,
            from: 'from',
          },
          developer: {
            comission: 'Commission',
            descriptionHide: 'Hide',
            descriptionShow: 'Show',
          },
          floorsList,
          heightsList,
        },
        filters: {
          byEmirate: {
            title: 'Emirate',
          },
          byDistrict: {
            title: 'District',
            search: 'Search',
            noData: 'No districts in selected emirates',
          },
          byDeveloper: {
            title: 'Developer',
            search: 'Search',
            noData: 'No companies in selected districts',
          },
          other: {
            title: 'More filters',
            showMe: 'Highlight on map',
            developersStock: 'Developers stock',
            offPlan: 'Off plan',
            resale: 'Resale',
            soldOut: 'Sold out',
            isLaunch: 'Launches',
            price: 'Price',
            size: 'Size',
            handover: 'Handover',
            ready: 'Ready',
            layout: 'Layout',
            unitType: 'Unit type',
            furnishing: 'Furnishing',
            paymentPlan: 'Payment plan',
            postHandover: 'Post handover',
            serviceCharge: 'Service charge',
            favourites: 'Favourite',
            showFavourites: 'Show favourites',
          },
        },
      },
      fullUnitsList: {
        ...units,
        floorPlan: 'Plan',
        number: 'Number',
        type: 'Type',
        layout: 'Layouts',
        height: 'Height',
        floor: 'Floor',
        size: 'Size',
        price: 'Price',
        view: 'View',
        action: 'Action',
        searchPlaceholder: 'Unit search',
        floorsList,
        heightsList,
      },
      blockCompanyGap: {
        text_one: ' {{count}} day left until the end of your current subscription. To extend access, contact your manager.',
        text_few: ' {{count}} days left until the end of your current subscription. To extend access, contact your manager.',
        text_many: ' {{count}} days left until the end of your current subscription. To extend access, contact your manager.',
        text_other: ' {{count}} days left until the end of your current subscription. To extend access, contact your manager.',
      },
    },
    personal: {
      companies: {
        all: 'All',
        broker: 'Broker',
        developer: 'Developer',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        block: 'Block',
        unblock: 'Unblock',
        employees_one: '{{count}} employee',
        employees_few: '{{count}} employees',
        employees_many: '{{count}} employees',
        employees_other: '{{count}} employees',
        editCompany: {
          company: 'Company',
          type: 'Type',
          agent: 'Agent',
          developer: 'Developer',
          data: 'Data',
          additionalInformation: 'Additional information',
          salesManagers: 'Sales Managers',
          addressesOfSalesOffices: 'Addresses of sales offices',
          name: 'Name',
          managersName: 'Name',
          managersContacts: 'Phone number',
          expiredAt: 'Days to block',
          companyBlocked: 'Blocked',
          maxUsersCount: 'Max users count',
          resale: 'Resale',
          resaleLabel: 'Resale access',
          maxResaleUnits: 'Max resale units',
          site: 'Website',
          address: 'Address',
          commission: 'Commission',
          commissionFrom: 'From %',
          commissionTo: 'To %',
          create: 'Create',
          save: 'Save',
        },
      },
      employees: {
        count_one: '{{count}} employee',
        count_few: '{{count}} employees',
        count_many: '{{count}} employees',
        count_other: '{{count}} employees',
        add: 'Add',
        delete: 'Delete',
        edit: 'Edit',
        companySelectionRequired: 'Company selection required',
        editEmployee: {
          additionalContacts: 'Additional contacts',
          user: 'User',
          name: 'Name',
          email: 'Email',
          phone: 'Phone',
          password: 'Password',
          position: 'Position',
          create: 'Create',
          edit: 'Edit',
          add: 'Add',
          tg: 'Telegram',
          whatsapp: 'WhatsApp',
          instagram: 'Instagram',
        },
        roles: {
          broker: 'Broker',
          head_of_sales_department: 'Administrator',
        },
      },
      catalog: {
        catalog: 'Catalog',
        clear: 'Clear',
        preview: 'Preview',
        settings: 'Settings',
        create: 'Create catalog',
        created: 'Catalog created',
        createdError: {
          title: 'Catalog created error',
          description: 'Please try again or try again later',
        },
        modal: {
          title: 'Specify directory options',
          layoutImage: 'With layouts',
          layoutTable: 'Table',
          darkTheme: 'Dark theme',
          lightTheme: 'Light theme',
          withMap: 'Project location with description of the area',
          hideUnitsNumbers: 'Hide units numbers',
          withDeveloper: 'Description of the developer',
          save: 'Save',
          cancel: 'Cancel',
        },
        settingsModal: {
          title: 'Personalise catalog',
          tab1: 'General',
          tab2: 'Additional slides',
          themeColor: 'Theme color',
          language: 'Language',
          ru: 'Russian',
          en: 'English',
          measures: 'Measures',
          currency: 'Currency',
          additionalCurrency: 'Additional currency',
          additionalCurrencyHint: 'Displays prices in two different currencies.',
          noAdditionalCurrency: 'No additional currency',
          unitsWithLayouts: 'Units with layouts',
          hideUnitsNumbers: 'Hide units numbers',
          fullUnitsList: 'Full units list',
          areaSlide: 'District description',
          developerSlide: 'Developer description',
          customSlide: 'Custom slide',
          customSlideTitle: 'Slide title',
          customSlideText: 'Text on the slide',
          customSlideHint: 'Displays at the end of the catalog. You can add image and text to the slide.',
          requiredField: 'Required field',
          reset: 'Reset to default',
          save: 'Save',
        },
        project: 'Project',
        number: 'Number',
        type: 'Type',
        layout: 'Layout',
        height: 'Height',
        floor: 'Floor',
        size: 'Square',
        price: 'Price',
        action: 'Action',
        heightsList,
        ...floorsList,
        yes: 'yes',
        no: 'No',
        noDataTitle: 'There is nothing in the catalog',
        noDataDescription: 'Add units to the catalog by selecting them on the map',
      },
      files: {
        files: 'Files',
        addFolder: 'Add folder',
        addFile: 'Add file',
      },
      menu: {
        companies: 'Companies',
        employees: 'Employees',
        files: 'Files',
        catalog: 'Catalog',
        resale: 'Resale',
        toTheMap: 'To the map',
      },
      resale: {
        searchPlaceholder: 'Unit search',
        add: 'Add',
        statuses,
        status: 'Status',
        project: 'Project',
        number: 'Number',
        price: 'Price',
        square: 'Area',
        floor: 'Floor',
        type: 'Type',
        layout: 'Layout',
        edit: 'Edit',
        district: 'District',
        sold_out: 'Sold out',
        ...units,
        floorsList,
        addUnitModal: {
          add: 'Add unit',
          edit: 'Edit unit',
          step1: 'Step 1',
          step2: 'Step 2',
          project: 'Project',
          projectPlaceholder: 'Select project',
          unitNumber: 'Unit number',
          unitNumberPlaceholder: 'Enter number',
          customNameCheckbox: 'The name does not match the user\'s name',
          contactName: 'Contact name',
          contactNamePlaceholder: 'Enter contact name',
          customPhoneCheckbox: 'The phone does not match the user\'s phone',
          contactPhone: 'Contact phone',
          contactPhonePlaceholder: 'Enter phone number',
          documentTemplate: '"Listing validation form" template',
          download: 'download',
          square: 'Area in ft²',
          squarePlaceholder: '0 ft²',
          floor: 'Floor',
          floorPlaceholder: 'Floor number',
          internalFloor: 'Approximate floor',
          internalFloorPlaceholder: 'Select',
          unitType: 'Unit type',
          unitTypePlaceholder: 'Select',
          rooms: 'Number of rooms',
          roomsPlaceholder: 'Select',
          height: 'Unit floors',
          heightPlaceholder: 'Select',
          price: 'Price in AED',
          pricePlaceholder: '0 AED',
          view: 'View',
          viewPlaceholder: 'List separated by commas',
          attachDocuments: 'Attach documents',
          button: {
            next: 'Next',
            create: 'Create',
            toModeration: 'To moderation',
          },
          firstDocument: {
            title: 'Oqood/Title deed',
          },
          secondDocument: {
            title: 'Validation form',
          },
          attachLayouts: 'Attach layouts',
          layouts: {
            title: 'Select images',
            formats: 'JPEG, PNG, PDF',
          },
          floorsList,
          heightsList,
        },
        changeStatusUnitModal: {
          project: 'Project',
          unitNumber: 'Unit number',
          square: 'Area',
          floor: 'Floor',
          unitType: 'Unit type',
          rooms: 'Rooms count',
          height: 'Height',
          price: 'Price',
          firstDocument: 'Oqood/Title deed',
          secondDocument: 'Validation form',
          layouts: 'Layouts',
          declineReason: 'Decline reason',
          deleteConfirmation: 'Are you sure you want to delete the unit?',
          soldOutConfirmation: "Are you sure you want to change the unit's status?",
          view: 'View',
          floorsList,
          heightsList,
          statuses,
          buttons: {
            decline: 'Decline',
            publish: 'Publish',
            delete: 'Delete',
            soldOut: 'Sold out',
            unpublish: 'Unpublish',
          },
        },
        declineReasonModal: {
          header: 'Decline reason',
          close: 'Close',
        },
      },
    },
    login: {
      welcome: 'Welcome 👋🏽',
      description: 'Start tracking the best properties in the UAE',
      email: 'Email',
      password: 'Password',
      login: 'Login',
      forgotPassword: 'Forgot password?',
      passwordRecovery: {
        header: 'Password recovery',
        body: 'Enter your email address and we will send you a link to reset your password.',
        forgotSubmited: 'We have sent you a letter, follow the link provided in it to create a new password.',
      },
      continue: 'Continue',
      ok: 'Ok',
    },
    resetPassword: {
      passwordReset: {
        header: 'Password recovery',
        body: 'Create a new password. It must be at least 8 characters, you can use uppercase and lowercase letters, as well as numbers and special characters.',
        password: 'New password',
        passwordConfirmation: 'Confirm your new password',
      },
      passwordSubmited: {
        header: 'You now have a new password!',
        body: 'The new password has been successfully created and you can now use it to log into Geniemap.',
      },
      createPassword: 'Save password',
      ok: 'Ok',
    },
    pdf: {
      create: 'Create',
      settings: 'Settings',
      back: 'Back',
      handover: 'Handover: ',
      serviceCharge: 'Service charge: ',
      amenities: 'Amenities',
      units: 'Units',
      fewRenders_one: 'In project {{ projects }} not enough renders. Please contact our manager.',
      fewRenders_other: 'In projects {{ projects }} not enough renders. Please contact our manager.',
      underStar: '* Payment plan for off plan units only',
      tableUnit: {
        number: 'Number',
        height: 'Height',
        bedroom: 'Bedrooms',
        square: 'Area',
        floor: 'Floor',
        view: 'View',
        price: 'Price',
      },
      imagesUnit: {
        title: 'Unit characteristics',
        number: 'Unit number:',
        price: 'Price:',
        type: 'Unit type:',
        square: 'Total unit area:',
        floor: 'Floor:',
        view: 'View:',
        finishing: 'Finishing:',
        height: 'Height:',
      },
    },
    common: {
      header: {
        logout: 'Logout',
        searchPlaceholder: 'Search',
        units: {
          language: 'Language',
          russianLanguage: 'Russian',
          englishLanguage: 'English',
          currency: 'Currency',
          measures: 'Measures',
        },
      },
      uploadArea: {
        add: 'Add',
        upload: 'Upload',
      },
      input: {
        noMatch: 'No match',
      },
      uploadModal: {
        description: 'Description',
        cancel: 'Cancel',
        save: 'Save',
      },
      authError: 'Authorization error',
    },
    ws: {
      catalog: {
        start_catalog_generate: 'Start catalog generate',
        catalog_generated: 'Catalog successfully created',
        warning: 'Warning',
      },
      auth: {
        logout: 'You have been logged out due to a large number of connections from your account.',
      },
    },
  },
};
