import { createAsyncThunk } from '@reduxjs/toolkit';

import { uploadFileApi } from 'store/common/api';

import {
  getDistrictsApi,
  createDistrictApi,
  editDistrictApi,
  getDistrictDevelopersApi,
  attachDistrictToProjectApi,
  getDistrictImagesApi,
  getDistrictApi,
  deleteDistrictApi,
} from './api';


export const getDistricts = createAsyncThunk(
  'districts/get',
  async (payload) => {
    const { data } = await getDistrictsApi(payload);

    return data;
  },
);

export const getDistrict = createAsyncThunk(
  'district/get',
  async (id) => {
    const { data } = await getDistrictApi(id);

    return data;
  },
);

export const createDistrict = createAsyncThunk(
  'districts/create',
  async (district) => {
    const { data } = await createDistrictApi(district);

    const ids = await Promise.all((district.photos || []).map(async (file) => {
      const formData = new FormData();

      formData.append('file', file);
      const { data: { fileId } } = await uploadFileApi(formData);

      return fileId;
    }));

    if (ids.length) {
      await attachDistrictToProjectApi({ districtId: data.id, ids });
    }

    return data;
  },
);

export const editDistrict = createAsyncThunk(
  'districts/edit',
  async (district) => {
    const { data } = await editDistrictApi(district);

    const ids = await Promise.all((district.photos || []).map(async (file) => {
      const formData = new FormData();

      formData.append('file', file);
      const { data: { fileId } } = await uploadFileApi(formData);

      return fileId;
    }));

    if (ids.length) {
      await attachDistrictToProjectApi({ districtId: data.id, ids });
    }
    return data;
  },
);

export const deleteDistrict = createAsyncThunk(
  'districts/delete',
  async (district) => {
    await deleteDistrictApi(district);

    return district;
  },
);

export const getDistrictDevelopers = createAsyncThunk(
  'districts/getDevelopers',
  async ({ districtId }) => {
    const { data } = await getDistrictDevelopersApi({ districtId });

    return data;
  },
);

export const getDistrictImages = createAsyncThunk(
  'districts/getImages',
  async ({ districtId }) => {
    const { data } = await getDistrictImagesApi({ districtId });

    return data;
  },
);
