// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader_YkAtI {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 2;\n  background-color: rgba(var(--ui-colors-system-white) / 0.4);\n  -webkit-backdrop-filter: blur(10px);\n          backdrop-filter: blur(10px);\n}", "",{"version":3,"sources":["webpack://./src"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,OAAO;EACP,MAAM;EACN,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,2DAA2D;EAC3D,mCAA2B;UAA3B,2BAA2B;AAC7B","sourcesContent":[".loader {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 2;\n  background-color: rgba(var(--ui-colors-system-white) / 0.4);\n  backdrop-filter: blur(10px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "loader_YkAtI"
};
export default ___CSS_LOADER_EXPORT___;
