import request from '../common/utils';


export const authApi = ({ email, password }) => request({
  url: '/login',
  method: 'POST',
  data: { email, password },
});

export const userApi = () => request({
  url: '/me',
  method: 'get',
});

export const forgotPasswordApi = (data) => request({
  url: '/forgot-password',
  method: 'post',
  data,
});

export const resetPasswordApi = (data) => request({
  url: '/reset-password',
  method: 'post',
  data,
});
