import { createSlice, createAction } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { setToken } from 'store/common/utils';

import {
  auth,
  getUser,
  forgotPassword,
  resetPassword,
} from './thunks';


const authToken = window.localStorage.getItem('token');
const logout = createAction('logout');
const initialState = {
  token: authToken,
  user: {},
  errors: {
    auth: '',
    forgot: '',
    reset: '',
  },
};

const reducers = {
  [logout]: (state) => {
    state.token = false;
    window.localStorage.setItem('token', '');
    localStorage.setItem('role', undefined);
    localStorage.setItem('catalog', undefined);
  },
};

const extraReducers = (builder) => builder
  .addCase(auth.fulfilled, (state, { meta, payload }) => {
    state.meta = meta;
    state.token = payload;
  })
  .addCase(auth.rejected, (state) => {
    state.errors.auth = t('common.authError');
  })

  .addCase(forgotPassword.rejected, (state, { payload }) => {
    state.errors.forgot = payload;
  })

  .addCase(resetPassword.rejected, (state, { payload }) => {
    state.errors.reset = payload;
  })

  .addCase(getUser.fulfilled, (state, { meta, payload }) => {
    state.meta = meta;
    state.user = payload;
    localStorage.setItem('role', payload.role);
  });

export const { actions, reducer, name } = createSlice({
  name: 'auth',
  initialState,
  reducers,
  extraReducers,
});

setToken(`Bearer ${authToken}`);
