import axios from 'axios';

import request from '../common/utils';


export const getExchangeApi = () => request({
  url: '/currencies/AED/rates',
  method: 'GET',
});

export const getRubExchange = () => axios({
  url: 'https://api.exchangerate.host/convert?from=AED&to=RUB',
  method: 'GET',
});

export const getUsdExchange = () => axios({
  url: 'https://api.exchangerate.host/convert?from=AED&to=USD',
  method: 'GET',
});
